import { useEffect, useState } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import {
  ChatBubbleBottomCenterIcon,
  ClockIcon,
  CubeIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";
import NavbarAssessment from "../Component/NavbarAssessment";
import axios from "axios";
import PageSpinner from "../Component/PageSpinner";
import { AssessmentContext } from "../Contexts/AssessmentContext";
import moment from "moment";
import PopUp from "../Component/PopUp";
import {
  setClearAll,
  setClearPinalties,
} from "../features/assessment/assessmentSlice";
import { useDispatch } from "react-redux";
const AssessmentLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [assessment, setAssessment] = useState(null);
  const [user] = useState(() => {
    return JSON.parse(sessionStorage.getItem("user")) || null;
  });
  const { candidate_test_id } = useParams();
  const getAssessment = async () => {
    setLoading(true);
    await axios
      .get(`/api/assessment/test/${candidate_test_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;

        if (result.success) {
          // setSummary(result.data.summary)
          setAssessment(result.data);
        }
      })
      .catch((e) => {
        // console.log(e);
      });

    setLoading(false);
  };

  useEffect(() => {
    getAssessment();
  }, []);

  useEffect(() => {
    if (assessment) {
      if (assessment.status == "finished") {
        const answers = JSON.parse(sessionStorage.getItem("answers"));
        if (answers) {
          sessionStorage.removeItem("answers");
        }
        const latest_timer = JSON.parse(sessionStorage.getItem("latest_timer"));
        if (latest_timer) {
          sessionStorage.removeItem("latest_timer");
        }
        dispatch(setClearPinalties());
      }
    }
  }, [assessment]);

  return (
    <>
      <div className="h-screen flex flex-col over">
        <NavbarAssessment assessment={assessment} playTimer={false} />
        {assessment?.status == "finished" && (
          <PopUp>
            <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate flex flex-col items-center justify-evenly h-[480px] py-2">
              <CheckCircleIcon width={"100"} color="#31CBAA" />
              <div className="text px-2 px-2">
                <p className="font-bold text-center text-lg mb-4">
                  This Test Has Been Completed
                </p>
                <p className="text-center text-base">
                  You have completed this test, and you cannot access it again.
                  Please return to the assessment page to work on other
                  available tests.{" "}
                </p>
              </div>

              <div className="flex mt-5 items-center justify-center w-[100%] flex-col">
                <button
                  className="hover:bg-violet-600 cursor-pointer bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed"
                  onClick={() => {
                    dispatch(setClearAll());
                    navigate("/assessment");
                  }}
                >
                  Back to Assessment
                </button>
                {/* <button
                className="text-grey-98  cursor-pointer  px-4 py-2 text-[16px] leading-[22.4px] text-black font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type="submit"
                onClick={handleClose}
              >
                Report Problem
              </button> */}
              </div>
            </div>
          </PopUp>
        )}

        <div className="fit-height flex-grow bg-gradient-to-r from-purple-400 via-purple-600 to-white px-2 py-3">
          <div className="w-full flex flex-col mdb:flex-row flex-1 px-2 md:px-0 gap-4 h-full">
            <div className="bg-main-violate-50 mdb:w-[40%] w-full flex justify-center items-center rounded-lg">
              {loading ? (
                <PageSpinner />
              ) : (
                <>
                  {assessment?.status != "finished" && (
                    <div className="box mdb:min-w-[450px] mdb:py-0 py-2">
                      <p className="text-main-violate-900 p-2 mdb:p-0 text-lg mdb:text-2xl font-medium mb-6 mdb:mb-16 ">
                        Hello {user.name},
                      </p>
                      <p className="text-main-violate-900 px-2 mdb:px-0 font-bold text-xl mdb:text-3xl">
                        Welcome to
                      </p>
                      <p className="text-main-violate-900 px-2 mdb:px-0 font-medium text-xl mdb:text-3xl mb-6 mdb:mb-16">
                        {assessment
                          ? assessment.company_assessment_test.assessment_test
                              .name
                          : "-"}
                      </p>
                      <div className="context mdb:bg-white w-full rounded-lg">
                        <div className="flex flex-wrap justify-between items-center mdb:px-4 mdb:py-4 px-2 py-2">
                          <div className="box-data mdb:pl-4 mdb:w-1/3 w-[50%]">
                            <span className="flex text-main-violate-900">
                              <ClockIcon width={20} />
                              <p className="text-base ml-1">Test Duration</p>
                            </span>
                            <p className=" font-semibold mt-2 text-main-violate-900">
                              {assessment
                                ? Math.floor(
                                    moment
                                      .duration(
                                        assessment.company_assessment_test
                                          .assessment_test.times,
                                        "seconds"
                                      )
                                      .asMinutes()
                                  )
                                : "-"}{" "}
                              minutes
                            </p>
                          </div>
                          <div className="box-data mdb:pl-4 mdb:w-1/3 w-[50%]">
                            <span className="flex text-main-violate-900">
                              <CubeIcon width={20} />
                              <p className="text-base ml-1">Category</p>
                            </span>
                            <p className=" font-semibold mt-2 text-main-violate-900">
                              {assessment
                                ? assessment.company_assessment_test
                                    .assessment_test.assessment.name
                                : "-"}
                            </p>
                          </div>
                          <div className="box-data mdb:pl-4 mdb:w-1/3 w-[50%] mt-5 mdb:mt-0">
                            <span className="flex text-main-violate-900">
                              <ChatBubbleBottomCenterIcon width={20} />
                              <p className="text-base ml-1">Questions</p>
                            </span>
                            <p className=" font-semibold mt-2 text-main-violate-900">
                              {assessment
                                ? assessment.company_assessment_test
                                    .assessment_test.total_question
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="content flex-grow w-full mdb:w-[60%]">
              {loading && (
                <div className="bg-white flex items-center justify-center rounded-lg h-full">
                  {" "}
                  <PageSpinner />
                </div>
              )}
              {!loading && (
                <>
                  {assessment?.status != "finished" && (
                    <AssessmentContext.Provider
                      value={{ assessment, setAssessment }}
                    >
                      <Outlet />
                    </AssessmentContext.Provider>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentLayout;
