import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlayIcon, PauseIcon, StopIcon } from "@heroicons/react/24/solid";
import { setPlayCount } from "../../features/assessment/assessmentSlice";
import "./style.css";

const CustomVideoPlayer = ({
  src,
  label = "Video",
  showPause = false,
  preventStop = true,
  maxPlays = 1,
}) => {
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const playCount = useSelector((state) => state.assessment?.playCount);
  const dispatch = useDispatch();

  useEffect(() => {
    const video = videoRef.current;
    console.log("src " , src);
    const updateTime = () => {
      setCurrentTime(video.currentTime);
    };

    const updateDuration = () => {
      setDuration(video.duration);
    };

    const handleEnded = () => {
      setIsPlaying(false);
    };

    video?.addEventListener("timeupdate", updateTime);
    video?.addEventListener("loadedmetadata", updateDuration);
    video?.addEventListener("ended", handleEnded);

    return () => {
      video?.removeEventListener("timeupdate", updateTime);
      video?.removeEventListener("loadedmetadata", updateDuration);
      video?.removeEventListener("ended", handleEnded);
    };
  }, [src]);

  useEffect(() => {
    console.log("render 1");
    if (videoRef?.current) {
      console.log("render 2");
      videoRef?.current.load();
    }
    console.log("render 3");
    if (playCount[src] === undefined) {
      setDisabled(false);
    } else {
      if (playCount[src] < maxPlays) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [src, playCount]);

  const handlePlay = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  const handlePause = () => {
    videoRef.current.pause();
    setIsPlaying(false);
  };

  const handleStop = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleProgressClick = (event) => {
    const progress = event.target;
    const rect = progress.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const newTime = (offsetX / progress.offsetWidth) * duration;
    videoRef.current.currentTime = newTime;
  };

  useEffect(() => {
    console.log("render 4");
    if (duration > 0 && currentTime >= duration) {
      console.log("render 5");
      setCurrentTime(0);
      dispatch(
        setPlayCount({
          url: src,
          count: playCount[src] === undefined ? 1 : playCount[src] + 1,
        })
      );
    }
  }, [currentTime]);

  // useEffect(() => {
  //   if (tempPlayCount > 0) {
  //     if (tempPlayCount >= maxPlays) {
  //       setDisabled(true);
  //     }
  //   }
  // }, [tempPlayCount]);

  return (
    <div className="bg-white rounded-xl w-full">
      {/* <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label> */}
      <div className="flex gap-4 items-center">
        {!disabled ? (
          <>
            <div className="flex items-center flex-col w-full">
              <video
                ref={videoRef}
                src={src}
                className="w-full max-h-[300px]"
              />
              <div className="flex gap-2 items-center w-full p-2">
                {isPlaying ? (
                  <button
                    onClick={handleStop}
                    className="bg-red-500 text-white p-3 rounded-full flex items-center justify-center mt-2 disabled:bg-red-400 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={preventStop}
                    title={preventStop ? "Stop is disabled" : ""}
                  >
                    <StopIcon className="w-[20px]" />
                  </button>
                ) : (
                  <button
                    onClick={handlePlay}
                    className="bg-blue-500 text-white p-3 rounded-full flex items-center justify-center mt-2"
                  >
                    <PlayIcon className="w-[20px]" />
                  </button>
                )}
                {showPause && isPlaying && (
                  <button
                    onClick={handlePause}
                    className="bg-yellow-500 text-white p-3 rounded-full flex items-center justify-center"
                  >
                    <PauseIcon className="w-[30px]" />
                  </button>
                )}
                <div className="flex-1">
                  <div className="mt-2 text-sm text-gray-700">
                    {formatTime(currentTime)} / {formatTime(duration)}
                  </div>
                  <progress
                    className="w-full h-[10px] progress-bar"
                    value={currentTime}
                    max={duration}
                    onClick={handleProgressClick}
                  ></progress>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="text-red-500 text-sm p-2">Max plays reached</div>
        )}
      </div>
    </div>
  );
};

export default CustomVideoPlayer;
